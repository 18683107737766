// ================================ Colors ================================

// Main colors
$color-main-one: #00B7CC;
$color-main-two: #4A0072;
$color-main-three: #FF1965;
$color-text : #ffffff;

// Main computed colors
$color-main-one-dark: #333333;

// ================================ Fonts ================================

// Main font
$font-main-one: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;